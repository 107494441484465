@import 'src/styles/animations';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #313336;

  &White {
    color: #fff;

    .number {
      border: 1px solid #fff;

      .circleIcon {
        circle {
          stroke: #fff;
        }
      }
    }
  }
}

.number {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  border-radius: 50%;
  border: 1px solid #cce4fa;
  margin-right: 20px;
}

.circleIcon {
  position: absolute;
  width: 56px;
  height: 56px;
  animation: rotate 30s linear infinite;

  circle {
    stroke: #0077E4;
  }
}

.title {
  max-width: 650px;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
}

@media (max-width: 768px) {
  .container {
    display: block;
    text-align: center;
  }

  .number {
    width: 40px;
    min-width: 40px;
    height: 40px;
    font-size: 28px;
    line-height: 30px;
    margin: 0 auto;

    &:after {
      width: calc(100% + 6px);
      height: calc(100% + 6px);
    }
  }

  .title {
    max-width: none;
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
  }
}
