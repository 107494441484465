@import "src/styles/animations";

.calculate {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 290px;
  height: 60px;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  background-color: #0077E4;
  transition: background-color 0.2s ease-in-out;
  z-index: 10000;
  animation: fadeIn 0.5s ease-in-out 0.2s both;
  filter: drop-shadow(0px 4px 19px rgba(40, 65, 130, 0.34));
  cursor: pointer;

  &:hover {
    background-color: #359AF8;
  }

  &Hidden {
    animation: fadeOut 0.28s ease-in-out both;
    pointer-events: none;
  }
}

.calculate::after {
  content: '';
  position: absolute;
  display: block;
  top: -7px;
  left: -7px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border: 1px solid rgba(0, 119, 228, 0.5)
}

@media (max-width: 768px) {
  .calculate {
    bottom: 15px;
    right: 15px;
    width: 210px;
    height: 50px;
    font-size: 14px;
    line-height: 16px;
  }
}
