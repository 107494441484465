@import "src/styles/animations";

.container {
  padding-bottom: 185px;
}

.header {
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  animation: moveDown 0.4s ease-out 0.5s both;
  padding-top: 52px;
  padding-bottom: 52px;
  padding-left: 10%;

  &NoDelay {
    animation-delay: 0.2s;
  }

  &Hidden {
    animation: moveUp 0.28s ease-in-out 0s both;
  }
}

.headerBg {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  transition: all 80ms linear;
  transform: translate3d(0, 0, 0);
  background-image: url(./img/Background_Sertificates.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;

  @media (hover: hover) and (pointer: fine) {
    display: block;
  }
}

.content {
  max-width: 1200px;
  transition: opacity 0.28s ease-in-out;
  padding: 0 130px;
  margin: 0 auto;

  &Hidden {
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .content {
    padding: 0 90px;
  }

  .header {
    background-image: url(./img/Background_Sertificates.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .headerBg {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    padding-bottom: 110px;
  }

  .content {
    padding: 0 30px;
  }

  .header {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    padding: 46px 0;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .container {
    padding-bottom: 75px;
  }

  .content {
    padding: 0 20px;
  }

  .header {
    font-size: 22px;
    line-height: 24px;
    padding: 35px 0;
  }
}

@media (max-width: 370px) {
  .container {
    padding-bottom: 55px;
  }

  .content {
    padding: 0 15px;
  }

  .header {
    line-height: 30px;
    padding: 30px 10px;
  }
}
