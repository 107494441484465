@import "src/styles/animations";

.content {
  position: relative;
  max-width: 885px;
  animation-name: showAndMoveDown30;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  margin: 55px auto 0;
}

.horizontalDividerWrap {
  display: flex;
  align-items: center;
  margin-bottom: 42px;
}

.caption {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;
  margin-right: 8px;
}

.horizontalDivider {
  flex-grow: 1;
  width: 100%;
  height: 1px;
  background-color: #E2E2E2;
  margin-top: 3px;
}

.list {
  display: flex;
  font-size: 14px;
  line-height: 20px;
}

.listItems {
  width: 100%;
  max-width: 50%;
}

.download, .link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.icon {
  width: 7%;
}

.downloadLink, .commonLink {
  width: 90%;
  text-decoration: none;
  cursor: pointer;
}

.downloadLink {
  color: #313336;

  &:hover {
    color: #0077E4;
  }
}

.commonLink {
  color: #0077E4;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 480px) {
  .content {
    margin-top: 35px;
  }

  .caption {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }

  .list {
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
  }

  .listItems {
    max-width: 100%;
  }

  .icon {
    width: 10%;
  }
}

@media (max-width: 370px) {
  .content {
    margin-top: 30px;
  }

  .caption {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
  }

  .list {
    line-height: 14px;
  }

  .download, .link  {
    margin-bottom: 15px;
  }
}
