@import "src/styles/animations";

.container {
  overflow: hidden;
  padding: 65px 30px 80px;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.eco {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.ecoImgWrap {
  width: 18%
}

.ecoImg {
  width: 100%;
  height: auto;
}

.ecoDescription {
  width: 60%;
  height: auto;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  background-color: #def8d5;
  border-radius: 42px;
  padding: 22px 20px;
}

.pollutionCharts {
  display: flex;
  justify-content: center;
  margin-top: 65px;
}

.chartWrap {
  position: relative;
  width: 48%;
  transform-origin: bottom;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 10%;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 1000;
    opacity: 0.5;
  }

  &::after {
    content: '';
    position: absolute;
    top: 14%;
    right: 20%;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 1000;
    opacity: 0.5;
  }
}

.firstCircle, .secondCircle, .thirdCircle {
  position: absolute;
  top: 13%;
  left: 76%;
  width: 66px;
  height: 66px;
  animation: scaleAndRotateOpacity 2s linear infinite backwards;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    background-color: #BBBBC5;
  }
}

.firstCircle {
  animation-delay: 0.2s;
}

.secondCircle {
  animation-delay: 0.8s;
}

.thirdCircle {
  animation-delay: 1.2s;
}

.chart {
  width: 100%;
  height: auto;
}

.trees {
  transform: translateX(40px);
}

.tree {
  animation: rotate2 5s ease-in-out 0.5s infinite;
  transform-origin: bottom left;
}

.green1 {
  animation: rotate1 5s ease-in-out infinite;
  transform-origin: center;
}

.green2 {
  animation: rotate2 5s ease-in-out infinite;
  transform-origin: bottom left;
}

.chartName {
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  margin-top: 23px;

  &Blue {
    color: #0077E4;
  }
}

@media (max-width: 1024px) {
  .ecoDescription {
    width: auto;
  }

  .chartWrap {
    &::before {
      width: 40px;
      height: 40px;
      top: -2%;
      right: 9%;
    }

    &::after {
      width: 50px;
      height: 50px;
      top: 11%;
      right: 22%;
    }
  }

  .firstCircle, .secondCircle, .thirdCircle {
    top: 10%;
    left: 74%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 50px 30px 65px;
  }

  .eco {
    display: block;
    margin-top: 35px;
  }

  .ecoImgWrap {
    width: 40%;
    margin: 0 auto;
  }

  .ecoDescription {
    line-height: 24px;
    padding: 18px 20px;
    margin: 10px auto 0;
  }

  .chartWrap {
    &::before {
      width: 32px;
      height: 32px;
      top: -4%;
      right: 8%;
    }

    &::after {
      width: 42px;
      height: 42px;
      top: 7%;
      right: 20%;
    }
  }

  .firstCircle, .secondCircle, .thirdCircle {
    top: 8%;
    left: 70%;

    &::after {
      width: 35%;
      height: 35%;
    }
  }
}

@media (max-width: 480px) {
  .ecoImgWrap {
    width: 35%;
  }

  .ecoDescription {
    padding: 16px 5px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 35px;
  }

  .pollutionCharts {
    flex-direction: column-reverse;
  }

  .chartWrap {
    width: 100%;

    &::before {
      width: 30px;
      height: 30px;
      top: -4%;
      right: 16%;
    }

    &::after {
      width: 38px;
      height: 38px;
      top: 7%;
      right: 21%;
    }
  }

  .firstCircle, .secondCircle, .thirdCircle {
    top: 7%;
    left: 69%;
    width: 55px;
    height: 55px;
  }

  .chartName {
    font-size: 14px;
    line-height: 22px;
    margin-top: 18px;
  }
}

@media (max-width: 425px) {
  .chartWrap::before {
    width: 28px;
    height: 28px;
    top: -4%;
    right: 14%;
  }
}

@media (max-width: 375px) {
  .container {
    padding: 40px 20px 50px;
  }

  .ecoImgWrap {
    width: 50%;
  }

  .ecoDescription {
    width: 100%;
    margin-top: 20px;
  }

  .chartWrap {
    &::before {
      width: 24px;
      height: 24px;
      top: 0;
      right: 12%;
    }

    &::after {
      width: 30px;
      height: 30px;
      top: 8%;
      right: 20%;
    }
  }

  .firstCircle, .secondCircle, .thirdCircle {
    top: 9%;
    left: 72%;
    width: 45px;
    height: 45px;
  }

  .pollutionCharts {
    margin-top: 50px;
  }
}
