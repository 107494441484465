@import "src/styles/animations";

.container {
  animation: fadeIn 0.2s ease-in-out 0.5s both;
  padding-top: 76px;
}

@media (max-width: 1024px) {
  .container {
    padding-top: 68px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-top: 50px;
  }
}
