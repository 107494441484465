@import "src/styles/animations";

.container {
  padding: 0 30px;
}

.subtitle {
  max-width: 540px;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  margin: 48px auto 64px;
}

.calculator {
  position: relative;
  max-width: 1040px;
  background-color: rgba(231, 241, 252, 0);
  padding: 42px 30px 90px;
  margin: 0 auto;
}

.labelWrap {
  position: absolute;
  display: flex;
  align-items: center;
  width: calc(50% - 144px);
  height: 96px;
  top: 32px;
  left: 30px;
  padding-right: 20px;
}

.label {
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  z-index: 20;
  opacity: 0;
}

.labelAnimate {
  animation-name: fadeIn;
  animation-delay: 300ms;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  transition: all 0.2s ease-in-out;
}

.labelInitVisible {
  animation-delay: 1800ms;
}

.arrow {
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  width: 50vw;
  background-color: #0077e4;
  z-index: 10;
  transform: translateX(-50vw);

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 100%;
    border-top: 48px solid transparent;
    border-bottom: 48px solid transparent;
    border-left: 20px solid #0077E4;
  }
}

.arrowAnimate {
  animation-name: moveRight;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.arrowInitVisible {
  animation-delay: 1500ms;
}

.content {
  max-width: 720px;
  margin: 0 auto;

  .fieldWrap, .unit, .unitMob, .calcBtn, .chartDescription {
    opacity: 0;
  }

  &Shown {
    .fieldWrap {
      animation: showAndMoveRight30 0.3s ease-in-out 0.6s both;
    }

    .unit, .unitMob {
      animation: showAndMoveDown30 0.3s ease-in-out 0.7s both;
    }

    .calcBtn {
      animation: showAndMoveLeft30 0.3s ease-in-out 0.8s both;
    }

    .chartDescription {
      animation: showAndMoveUp30 0.3s ease-in-out 0.9s both;
    }
  }
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spacer,
.right {
  width: 100%;
  flex-grow: 1;
}

.fieldWrap {
  position: relative;
}

.field {
  height: 60px;
  width: 160px;
  text-align: right;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  color: #000;
  background-color: #fff;
  border: 1px solid #0077E4;
  padding: 12px;
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  color: #0077E4;
  font-size: 14px;
  line-height: 28px;
}

.unitMob {
  display: none;
  width: 60px;
}

.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unitMob,
.unit {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  margin-left: 16px;
}

.calcBtn {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-family: 'Roboto', sans-serif;
  background-color: #0077E4;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  padding: 24px;
  cursor: pointer;

  &:hover {
    background-color: #359AF8;
  }
}

.chartDescription {
  color: #313336;
  font-size: 16px;
  line-height: 22px;
  margin: 46px 0 30px;
}

.chartWrap {
  position: relative;

  &[data-visibility=true] {
    .gridWrap,
    .gridWrapMob {
      animation: clip 0.8s linear 0.5s both;
    }

    .scaleItem {
      display: block;
    }
  }
}

.gridWrap,
.gridWrapMob {
  clip-path: inset(0% 100%);
  border: 1px solid #cce4fa;
}

.gridWrapMob {
  display: none;
}

.scale {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //display: grid;
  //grid-template-columns: repeat(45, 1fr);
  //grid-template-rows: repeat(22, 1fr);
}

.scaleItem {
  display: none;
  position: absolute;
  bottom: 0;
  animation-name: showAndBounce;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  transform-origin: bottom;

  &WithResult {
    .defaultRect {
      transform: scale3d(1, 0, 1);
    }

    .resultRect {
      transform: scale3d(1, 1, 1);
    }

    .scaleLabel {
      color: #0280F4;
    }

    .resultValue {
      opacity: 1;
      transform: translate3d(0, -100%, 0);
    }
  }

  &:nth-child(1) {
    left: calc(4 * 100% / 45);
    width: calc(7 * 100% / 45);
    height: calc(100% / 22 + 1px);
    animation-delay: 1.4s;
  }

  &:nth-child(2) {
    left: calc(14 * 100% / 45 + 1px);
    width: calc(7 * 100% / 45);
    height: calc(5 * 100% / 22 + 1px);
    animation-delay: 1.6s;
  }

  &:nth-child(3) {
    left: calc(24 * 100% / 45);
    width: calc(7 * 100% / 45);
    height: calc(10 * 100% / 22);
    animation-delay: 1.8s;
  }

  &:nth-child(4) {
    left: calc(34 * 100% / 45);
    width: calc(7 * 100% / 45);
    height: calc(20 * 100% / 22);
    animation-delay: 2s;
  }
}

.rect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transform-origin: bottom;
  z-index: 10;
}

.defaultRect {
  @extend .rect;
  background-color: #cce4fa;
}

.resultRect {
  @extend .rect;
  background-color: #0280F4;
  transform: scale3d(1, 0, 1);
}

.resultValue {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 17px;
  line-height: 38px;
  font-weight: 600;
  text-align: center;
  transition-property: all;
  transition-duration: 0.3s;
  transform: translate3d(0, 0, 0);
  z-index: 5;
  white-space: nowrap;
}

.scaleLabel {
  position: absolute;
  top: calc(100% + 13px);
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #80bbf2;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 1024px) {
  .labelWrap {
    width: calc(50% - 126px);
    padding-right: 30px;
  }

  .label {
    font-size: 15px;
    line-height: 20px;
  }

  .field {
    width: 140px;
  }

  .unit {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 768px) {
  .labelWrap {
    width: calc(100% - 60px);
    height: 68px;
  }

  .arrow {
    width: 100vw;
    transform: translateX(-100vw);

    &:after {
      border-top: 34px solid transparent;
      border-bottom: 34px solid transparent;
      border-left: 20px solid #0077E4;
    }
  }

  .arrowAnimate {
    animation-name: moveRightMob;
  }

  .calculator {
    padding-top: 120px;
  }

  .form {
    flex-wrap: wrap;
  }

  .spacer {
    display: none;
  }

  .fieldWrap {
    width: calc(100% - 80px);
  }

  .field {
    width: 100%;
  }

  .error {
    top: unset;
    bottom: 0;
    left: 10px;
    pointer-events: none;
  }

  .unitMob {
    display: block;
  }

  .right {
    margin-top: 20px;
  }

  .unit {
    display: none;
  }

  .calcBtn {
    width: 100%;
  }

  .resultValue {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 600px) {
  .calculator {
    padding-bottom: 40px;
  }

  .calcBtn {
    padding: 16px;
  }

  .chartDescription {
    font-size: 14px;
    line-height: 18px;
    margin: 30px 0 10px;
  }

  .chartWrap {
    margin-left: 20px;
  }

  .gridWrap {
    display: none;
  }

  .gridWrapMob {
    display: block;
  }

  .scale {
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(41, 1fr);
  }

  .scaleItem {
    animation-name: showAndBounceHorizontal;
    transform-origin: left;
    bottom: unset;

    &WithResult {
      .defaultRect {
        transform: scale3d(0, 1, 1);
      }

      .resultValue {
        transform: translate3d(0, 0, 0);
      }
    }

    &:nth-child(1) {
      top: calc(2 * 100% / 41 + 1px);
      left: 0;
      height: calc(7 * 100% / 41);
      width: calc(1 * 100% / 20 + 1px);
    }

    &:nth-child(2) {
      top: calc(12 * 100% / 41 + 1px);
      left: 0;
      height: calc(7 * 100% / 41);
      width: calc(5 * 100% / 20 + 1px);
    }

    &:nth-child(3) {
      top: calc(22 * 100% / 41);
      left: 0;
      height: calc(7 * 100% / 41);
      width: calc(10 * 100% / 20);
    }

    &:nth-child(4) {
      top: calc(32 * 100% / 41);
      left: 0;
      height: calc(7 * 100% / 41);
      width: calc(20 * 100% / 20);

      .resultValue {
        width: 100%;
        left: 0;
        color: #fff;
        z-index: 15;
      }
    }
  }

  .defaultRect,
  .resultRect {
    transform-origin: left;
  }

  .resultRect {
    transform: scale3d(0, 1, 1);
  }

  .resultValue {
    width: auto;
    top: calc(50% - 18px);
    left: calc(100% + 10px);
    font-size: 20px;
    line-height: 26px;
    transform: translate3d(-20px, 0, 0);
  }

  .scaleLabel {
    transform-origin: left;
    transform: rotate(-90deg) translateX(-50%);
    white-space: nowrap;
    top: calc(50% - 11px);
    width: auto;
    left: -15px;
  }
}

@media (max-width: 400px) {
  .labelWrap {
    padding-right: 20px;
  }

  .label {
    font-size: 14px;
    line-height: 18px;
  }
}
