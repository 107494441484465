@import "src/styles/animations";

.container {
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 1120;
  animation-name: fadeIn;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  
  &FadeOut {
    animation-name: fadeOut;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;
    animation-fill-mode: backwards;
  }
}

.wraper {
  display: table-cell;
  vertical-align: middle;
}

.content {
  position: relative;
  max-width: 516px;
  max-height: 95vh;
  background-color: #fff;
  overflow: auto;
  border-radius: 4px;
  padding: 48px 36px;
  margin: 0 auto;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }

  &:hover > .close *{
    stroke: #0077E4;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .content {
    max-width: 420px;
    padding-top: 56px;
  }

  .closeButton {
    top: 15px;
    right: 15px;
  }
}

@media (max-width: 480px) {
  .content {
    width: 100%;
    height: auto;
    border-radius: 0;
    padding: 56px 20px 30px;
  }

  .closeButton {
    top: 12px;
    right: 12px;
  }
}

@media (max-width: 370px) {
  .content {
    padding: 50px 20px 30px;
  }

  .closeButton {
    top: 10px;
    right: 10px;
  }
}

