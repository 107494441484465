@import "src/styles/animations";

.container {
  padding: 0 30px;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 64px;
}

.infoText {
  width: 45%;
}

.text {
  font-size: 16px;
  line-height: 28px;
}

.listTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin: 34px 0;
}

.list {
  font-size: 16px;
  line-height: 28px;
}

.chartWrap {
  width: 45%;

  &[data-visibility=true] {
    .chart {
      &Rect1, &Rect2, &Rect3, &Rect4, &Pic1, &Pic2, &Pic3, &Pic4 {
        display: block;
      }
    }
  }
}

.chart {
  min-width: 100%;
  height: auto;

  &Rect1, &Rect2, &Rect3, &Rect4, &Pic1, &Pic2, &Pic3, &Pic4 {
    display: none;
  }

  &Rect1, &Rect2, &Rect3, &Rect4 {
    transform-origin: bottom;
  }

  &Pic1, &Pic2, &Pic3, &Pic4 {
    transform-origin: center;
    transform-box: fill-box;
  }

  &Rect1 {
    animation: showAndBounce 1s ease-in-out 0s both;
  }
  &Rect2 {
    animation: showAndBounce 1s ease-in-out 0.2s both;
  }
  &Rect3 {
    animation: showAndBounce 1s ease-in-out 0.4s both;
  }
  &Rect4 {
    animation: showAndBounce 1s ease-in-out 0.6s both;
  }
  &Pic1 {
    animation: rotateAndScaleAll 0.5s ease-in-out 0.4s both;
  }
  &Pic2 {
    animation: rotateAndScaleAll 0.5s ease-in-out 0.6s both;
  }
  &Pic3 {
    animation: rotateAndScaleAll 0.5s ease-in-out 0.8s both;
  }
  &Pic4 {
    animation: rotateAndScaleAll 0.5s ease-in-out 1s both;
  }
}

.example {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;

  &[data-visibility=true] {
    .connector {
      display: block;
    }

    .features {
      display: flex;
    }
  }
}

.boiler {
  width: 30%;
  position: relative;
  z-index: 10;
}

.boilerImg {
  width: 100%;
  height: auto;
}

.connector {
  display: none;
  position: absolute;
  top: 50%;
  left: 92%;
  width: 25%;
  height: 1px;
  animation: restoreWidth25 0.2s linear 0s both;
  background-color: #922033;
  
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -10%;
    width: 15px;
    height: 15px;
    background-color: #922033;
    border-radius: 50%;
  }
}

.features {
  display: none;
  position: relative;
  justify-content: space-between;
  width: 65%;
}

.redLine {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #922033;
  animation: restoreWidth 1.5s linear 0.2s both;
}

.feature {
  position: relative;
  width: 30%;
  animation-name: scaleIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.feature1 {
  animation-delay: 0.4s;

  .name {
    animation-delay: 0.7s;
  }
}

.feature2 {
  animation-delay: 0.8s;

  .name {
    animation-delay: 1.1s;
  }
}

.feature3 {
  animation-delay: 1.2s;

  .name {
    animation-delay: 1.5s;
  }
}

.nameWrap, .nameWrap3 {
  position: absolute;
  bottom: calc(100% + 14px);
  left: 0;
  max-width: 255px;
  width: 100%;
  text-align: center;
}

.name {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #922033;
  animation-name: showAndMoveUp30;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.imageFeature {
  width: 100%;
  height: auto;
  border: 1px solid #922033;
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .infoText, .chartWrap {
    width: 48%;
  }

  .listTitle {
    margin: 28px 0;
  }

  .list {
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .info {
    flex-direction: column-reverse;
    margin-top: 35px;
  }

  .infoText {
    width: 100%;
  }

  .chartWrap {
    width: 100%;
  }

  .infoText {
    margin-top: 20px;
  }

  .listTitle {
    margin: 20px 0;
  }

  .text, .listTitle, .list {
    line-height: 24px;
  }

  .example {
    flex-direction: column;
    justify-content: space-between;
  }
  
  .boiler {
    width: 60%;
  }

  .connector {
    display: block;
    top: 82%;
    left: 70%;
    width: 1px;
    height: 18%;
    animation: none;

    &::before {
      top: -5%;
      left: -8px;
      width: 20px;
      height: 20px;
    }
  }

  .features {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .redLine {
    top: 25%;
    left: 70%;
    width: 1px;
    height: 50%;
    animation: none;
  }

  .feature {
    width: 60%;
    animation: none;
  }

  .feature:not(:nth-child(2)) {
    margin-top: 30px;
  }

  .nameWrap, .nameWrap3 {
    max-width: 150px;
    text-align: right;
    bottom: 50%;
    left: calc(-90% - 30px);
  }

  .name {
    font-size: 20px;
    line-height: 24px;
    animation: none;
  }
}

@media (max-width: 480px) {
  .text, .listTitle, .list {
    font-size: 14px;
    line-height: 22px;
  }

  .example {
    margin-top: 30px;
  }

  .boiler {
    width: 70%;
  }

  .connector {
    top: 82%;
    left: 73%;
    
    &::before {
      top: -5%;
      left: -6px;
      width: 12px;
      height: 12px;
    }
  }

  .features {
    margin-left: 20%;
  }

  .redLine {
    left: 60%;
  }

  .feature {
    width: 80%;
  }

  .nameWrap, .nameWrap3 {
    max-width: 110px;
    bottom: 40%;
    left: calc(-65% - 20px);
  }

  .nameWrap3 {
    bottom: 50%;
  }
  
  .name {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 370px) {
  .container {
    padding: 0 20px;
  }

  .text, .listTitle, .list {
    font-size: 12px;
    line-height: 16px;
  }

  .example {
    margin-top: 25px;
  }

  .boiler {
    width: 75%;
  }

  .feature:not(:nth-child(2)) {
    margin-top: 20px;
  }

  .nameWrap, .nameWrap3 {
    left: calc(-74% - 20px);
  }

  .name {
    font-size: 12px;
    line-height: 18px;
  }
}
