@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes rotate {
  0%   { transform: rotate3d(0, 0, 1, 0deg); }
  100% { transform: rotate3d(0, 0, 1, 360deg); }
}

@keyframes rotateReverse {
  0%   { transform: rotate3d(0, 0, 1, 0deg); }
  100% { transform: rotate3d(0, 0, 1, -360deg); }
}

@keyframes rotate1 {
  0%   { transform: rotate3d(0, 0, 1, 1deg); }
  50%  { transform: rotate3d(0, 0, 1, -1deg); }
  100% { transform: rotate3d(0, 0, 1, 1deg); }
}

@keyframes rotate2 {
  0%   { transform: rotate3d(0, 0, 1, 2deg); }
  50%  { transform: rotate3d(0, 0, 1, -2deg); }
  100% { transform: rotate3d(0, 0, 1, 2deg); }
}

@keyframes scaleAndFadeIn {
  0%   { opacity: 0; transform: scale3d(0.8, 0.8, 1);}
  100% { opacity: 1; transform: scale3d(1, 1, 1);}
}

@keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes scaleInAndBounce {
  0%   { transform: scale(1, 0); }
  50%  { transform: scale(1, 1.2); }
  75%  { transform: scale(1, 0.8); }
  100% { transform: scale(1, 1); }
}

@keyframes showAndBounce {
  0%   { transform: scale3d(1, 0,    1); }
  50%  { transform: scale3d(1, 1.1,  1); }
  75%  { transform: scale3d(1, 0.94, 1); }
  100% { transform: scale3d(1, 1,    1); }
}

@keyframes showAndBounceHorizontal {
  0%   { transform: scale3d(0, 1, 1); }
  50%  { transform: scale3d(1.1, 1, 1); }
  75%  { transform: scale3d(0.94, 1, 1); }
  100% { transform: scale3d(1, 1, 1); }
}

@keyframes showAndMoveUp {
  0%   { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes showAndMoveRight30 {
  0%   { opacity: 0; transform: translateX(-30px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes showAndMoveLeft30 {
  0%   { opacity: 0; transform: translateX(30px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes showAndMoveUp30 {
  0%   { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes showAndMoveDown30 {
  0%   { opacity: 0; transform: translateY(-30px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes showAndMoveDown {
  0%   { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeAndMoveUp {
  0%   { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px); }
}

@keyframes moveDown {
  0%   { transform: translateY(-100%); }
  100% { transform: translateY(0%); }
}

@keyframes moveUp {
  0%   { transform: translateY(0%); }
  100% { transform: translateY(-100%); }
}

@keyframes moveLeft500 {
  0%   { transform: translateX(500px); }
  100% { transform: translateX(0); }
}

@keyframes moveRight {
  0%   { transform: translateX(-50vw) }
  100% { transform: translateX(0) }
}

@keyframes fadeAndMoveFromBaseToRight100 {
  0%   { opacity: 1; transform: translateX(0) }
  100% { opacity: 0; transform: translateX(100px) }
}

@keyframes moveDownAll {
  0%   { transform: translateY(-100%) }
  100% { transform: translateY(0) }
}

@keyframes moveRightMob {
  0%   { transform: translateX(-100vw) }
  100% { transform: translateX(0) }
}

@keyframes moveRightHeader {
  0%   { transform: translateX(-100%) }
  100% { transform: translateX(0) }
}

@keyframes rotate3d {
  0%   { transform: rotate3d(1, 0, 0, 90deg); }
  100% { transform: rotate3d(1, 0, 0, 0deg); }
}

@keyframes collapse {
  0%   { width: 100%; }
  100% { width: 0; }
}

@keyframes restoreWidth {
  0%   { width: 0; }
  100% { width: 100%; }
}

@keyframes restoreWidth25 {
  0%   { width: 0; }
  100% { width: 25%; }
}

@keyframes collapseHorizontal {
  0%   { height: 100%; }
  100% { height: 0; }
}

@keyframes grow {
  0%   { height: 0;    background-color: #313336; }
  50%  { height: 100%; background-color: #313336; }
  100% { height: 100%; background-color: #E2E2E2; }
}

@keyframes growHorizontal {
  0%   { width: 0;    background-color: #313336; }
  50%  { width: 100%; background-color: #313336; }
  100% { width: 100%; background-color: #E2E2E2; }
}

@keyframes scaleAndRotate {
  0%   { transform: scale(1) rotate(0deg); }
  50%  { transform: scale(1.25) rotate(4deg); }
  100% { transform: scale(1) rotate(0deg); }
}

@keyframes rotateAndScaleAll {
  0%   { transform: scale(0) rotate(-360deg); }
  100% { transform: scale(1) rotate(0deg); }
}

@keyframes clip {
  0%   { clip-path: inset(0% 100%); }
  100% { clip-path: inset(0% 0%); }
}

@keyframes scaleAndRotateOpacity {
  0%   { opacity: 1; transform: scale(0.8) rotate(0deg); }
  50%  { opacity: 0.9; transform: scale(1.5) rotate(-50deg); }
  75%  { opacity: 0.7; transform: scale(1.8) rotate(-75deg); }
  100% { opacity: 0; transform: scale(2.5) rotate(-90deg); }
}

@keyframes dashOffset {
  0%   { stroke-dashoffset: 0; }
  100% { stroke-dashoffset: 400; }
}

@keyframes dashOffsetReverse {
  0%   { stroke-dashoffset: 0; }
  100% { stroke-dashoffset: -400; }
}

@keyframes scaleIn {
  0%   { transform: scale(0); }
  100% { transform: scale(1); }
}
