.container {
  padding: 0 30px;
  margin: 80px 0;
}

.content {
  max-width: 1200px;
  background: url(./img/ant.jpg) bottom right no-repeat #FFFFFF;
  background-size: 34%;
  border-radius: 20px;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  padding: 70px 30px 45px;
  margin: 0 auto;
}

.title {
  max-width: 560px;
}

.description {
  max-width: 863px;
  text-align: left;
  font-size: 16px;
  line-height: 28px;
  margin-left: 52px;
  margin-top: 30px;

  &:first-child {
    margin-top: 60px;
  }
}

.subTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin-left: 9%;
  margin-top: 46px;
}

.plan {
  display: flex;
  margin-top: 86px;
  margin-left: 50px;
  padding-left: 24px;
}

.planItemFirst, .planItemSecond {
  position: relative;
}

.planItemFirst {
  width: 30%;
}

.planItemSecond {
  width: 32%;
  margin-left: 3%;
}

.planImg {
  width: 100%;
  height: auto;
}

.picNameFirst, .picNameSecond {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 9%;
  font-size: 16px;
  line-height: 28px;
}

.picNameSecond {
  color: #0077E4;
}

.blueRect {
  position: absolute;
  top: 65%;
  right: 1%;
  background-color: #0077E4;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  border: 3px solid #FFFFFF;
  border-radius: 2px;
  padding: 5px 10px;
}

.info {
  font-size: 12px;
  line-height: 28px;
  color: #0077E4;
  margin-left: 92px;
}

@media (max-width: 1024px) {
  .planNames {
    display: none;
  }

  .description {
    margin-right: 20px;
  }

  .subTitle {
    margin-left: 10%;
    margin-top: 36px;
  }

  .picNameFirst, .picNameSecond {
    font-size: 14px;
    line-height: 24px;
  }
  
  .blueRect {
    font-size: 10px;
    line-height: 12px;
    padding: 5px 5px;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 60px 0;
  }

  .content {
    padding: 55px 30px 40px;
  }

  .description {
    margin-left: 10px;
    margin-top: 30px;

    &:first-child {
      margin-top: 50px;
    }
  }

  .plan {
    margin-top: 50px;
    margin-left: 0;
  }

  .subTitle {
    font-size: 14px;
    line-height: 26px;
    margin-left: 5%;
    margin-top: 36px;
  }

  .picNameFirst, .picNameSecond {
    font-size: 10px;
    line-height: 18px;
  }
  
  .blueRect {
    font-size: 8px;
    line-height: 10px;
    padding: 3px 3px;
  }

  .info {
    line-height: 20px;
    margin-left: 45px;
  }
}

@media (max-width: 480px) {
  .content {
    background-image: none;
    padding: 55px 20px 40px;
  }

  .description {
    font-size: 14px;
    line-height: 22px;
    margin-left: 0;
    margin-top: 20px;

    &:first-child {
      margin-top: 30px;
    }
  }

  .subTitle {
    max-width: 200px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin: 30px auto 0;
  }

  .plan {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .planItemFirst {
    width: 98%;
    margin-bottom: 20%;
  }
  
  .planItemSecond {
    width: 100%;
    margin-left: 0;
  }

  .picNameFirst, .picNameSecond {
    font-size: 12px;
    line-height: 20px;
  }

  .blueRect {
    font-size: 12px;
    line-height: 18px;
    padding: 4px 8px;
  }

  .info {
    margin-left: 40px;
  }
}

@media (max-width: 370px) {
  .container {
    padding: 0 20px;
  }

  .description {
    font-size: 12px;
    line-height: 16px;
    margin-top: 15px;

    &:first-child {
      margin-top: 25px;
    }
  }

  .subTitle {
    font-size: 14px;
    line-height: 20px;
    margin-top: 25px;
  }

  .planItemFirst {
    margin-bottom: 16%;
  }

  .picNameFirst, .picNameSecond {
    font-size: 10px;
    line-height: 18px;
  }

  .blueRect {
    line-height: 14px;
  }

  .info {
    font-size: 10px;
    line-height: 18px;
  }
}
