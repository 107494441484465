@import "src/styles/animations";

.container {
  animation: showAndMoveDown30 0.3s linear 0.5s both;
  margin-bottom: 50px;
}

.horizontalDividerWrap {
  display: flex;
  align-items: center;
  margin-bottom: 42px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;
  margin-right: 8px;
}

.horizontalDivider {
  flex-grow: 1;
  width: 100%;
  height: 1px;
  background-color: #E2E2E2;
  margin-top: 3px;
}

.listItemBlock {
  margin-left: 32px;
  font-size: 18px;
  line-height: 34px;
}

.contact {
  color: #0077E4;
  cursor: pointer;
}

@media (max-width: 480px) {
  .content {
    margin-bottom: 30px;
  }

  .title {
    font-size: 18px;
    line-height: 24px;
  }

  .horizontalDividerWrap {
    margin-bottom: 32px;
  }

  .listItemBlock {
    margin-left: 26px;
    font-size: 16px;
    line-height: 28px;
  }
}
