.container {
  position: relative;
  text-align: center;
  color: #fff;
}

.videoDesc {
  border: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  padding: 80px 30px;
}

.trainingText {
  max-width: 656px;
  font-size: 16px;
  line-height: 28px;
  margin-top: 45px;
}

.watchBtn {
  position: relative;
  width: 330px;
  height: 60px;
  font-size: 18px;
  line-height: 22px;
  vertical-align: middle;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #0077E4;
  transition: all 0.2s ease-in-out;
  border: none;
  padding: 19px 0 0;
  cursor: pointer;

  &:hover {
    background-color: #359AF8;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: -7px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border: 1px solid rgba(0, 119, 228, 0.5)
  }
}

@media (max-width: 768px) {
  .container {
    padding-bottom: 0px;
  }

  .trainingText {
    margin-bottom: 75px;
  }

  .watchBtn {
    padding-bottom: 19px;
  }
}

@media (max-width: 480px) {
  .container {
    background-image: url(./gif/mobile_gif.gif);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 0px;
  }

  .content {
    position: static;
    padding: 50px 30px;
  }

  .videoDesc {
    display: none;
  }

  .trainingText {
    margin-bottom: 70px;
  }

  .watchBtn {
    width: 300px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 370px) {
  .trainingText {
    font-size: 14px;
    line-height: 24px;
    margin: 28px 0;
  }

  .watchBtn {
    width: 260px;
    font-size: 14px;
    line-height: 18px;;
  }
}
