@import "src/styles/animations";

.container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  transition: opacity 0.3s ease-in-out;
  padding: 0 40px;

  &Hidden {
    opacity: 0;
  }
}

.withoutAnimations {
  .ecoIcon, .name, .description, .verticalDividerWrap, .verticalDivider, .docsIntro, .doc, .tableRow, .preview {
    animation: none;
  }

  .verticalDividerWrap {
    width: 1px;
  }

  .verticalDivider {
    height: 100%;
    background-color: #E2E2E2;
  }
}

.captionWrap {
  width: 100%;
  display: flex;
  align-items: center;
}

.caption {
  width: 70%;
  padding-right: 20px;
}

.ecoIcon {
  display: block;
  width: 100%;
  max-width: 147px;
  height: auto;
  animation: scaleInAndBounce 0.4s linear 0.5s both;
  margin-left: 16px;
}

.name {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  animation: showAndMoveUp 0.3s ease-in-out 0.3s both;
}

.description {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  animation: showAndMoveUp 0.3s ease-in-out 0.5s both;
}

.features {
  position: relative;
  width: 70%;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.5px;
  padding-right: 20px;
  margin-top: 28px;
}

.docs {
  position: relative;
  width: 30%;
  padding: 0 30px 0 16px;
  margin-top: 28px;
}

.verticalDividerWrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: collapse 0.4s linear 1.2s both;
}

.verticalDivider {
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  left: 0;
  animation: grow 0.5s linear 0.8s both;
}

.docsIntro {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-left: 4px;
  animation: showAndMoveUp 0.3s ease-in-out 1s both;
}

.doc {
  display: flex;
  align-items: center;
  animation-name: showAndMoveUp;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin-top: 6px;
}

.docLink {
  width: 84%;
  font-size: 16px;
  line-height: 22px;
  color: #0077E4;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &Downloadable {
    color: #313336;

    &:hover {
      color: #0077E4;
      text-decoration: none;
    }
  }
}

.downloadIcon, .linkIcon {
  width: 16%;
}

.tableWrap {
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  scrollbar-color: #0077E4 #e6f1fc;
  scrollbar-width: auto;
  margin-top: 30px;
  padding-right: 30px;

  &::-webkit-scrollbar {
    height: 10px;
    background-color: #e6f1fc;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #0077E4;
    border-radius: 20px;
    border: 2px solid #e6f1fc;
  }
}

.table {
  width: 100%;
  margin-bottom: 8px;
}

.tableRow {
  animation-name: rotate3d;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;

  &:first-child{
    .tableCell {
      color: #fff;
      font-weight: 600;
      background-color: #0077E4;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  &:last-child {
    .tableCell {
      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.tableCell {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  background-color: #e6f1fc;
  padding: 10px;

  &:first-child {
    width: 30%;
    background-color: #cce4fa;
  }
}

.preview {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  animation-name: showAndMoveUp;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  margin-top: 60px;
}


.previewDescription {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-top: 64px;
}

.previewItem {
  width: 100%;
  max-width: 33%;
  padding: 0 30px;

  &Wide {
    max-width: 100%;
  }
}

.previewImage {
  width: 100%;
  height: auto;
}

.previewVideo {
  position: relative;
  width: 93%;
  height: 0;
  padding-bottom: 56%;
  margin-top: 56px;
}

.video {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (max-width: 1024px) {
  .container {
    width: 100%;
    margin-top: 18px;
    padding: 0 30px;
  }

  .caption {
    width: 66%;
  }

  .ecoIcon {
    width: 23%;
  }
  
  .features {
    width: 100%;
    border-right: none;
    padding-right: 0;
  }

  .docs {
    order: 98;
    width: 100%;
    border-top: 1px solid #E2E2E2;
    margin-top: 32px;
    padding-top: 18px;
    padding-left: 0;
  }

  .docLink {
    width: 88%;
  }
  
  .downloadIcon, .linkIcon {
    width: 12%;
  }

  .tableWrap {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0;
    scrollbar-color: #0077E4 #e6f1fc;
    scrollbar-width: auto;

    &::-webkit-scrollbar {
      height: 10px;
      background-color: #e6f1fc;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0077E4;
      border-radius: 20px;
      border: 2px solid #e6f1fc;
    }
  }

  .preview {
    margin-top: 40px;
  }

  .previewItem {
    flex-grow: 0;
    margin: 0 auto;
  }

  .previewVideo {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 20px;
  }

  .caption {
    width: 72%;
  }

  .ecoIcon {
    display: none;
  }

  .features {
    margin-top: 12px;
  }

  .tableWrap {
    overflow-x: scroll;
  }
  .preview {
    margin-top: 20px;
  }

  .previewItem {
    max-width: 95%;
  }
}
