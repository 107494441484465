body {
  min-width: 320px;
  color: #313336;
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  background-color: transparent;
}

b {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: 400;
  margin: 0;
}

ul, ol, li {
  padding: 0;
  margin: 0;
}

textarea:focus,
input:focus {
  outline: none;
  box-shadow: none;
}