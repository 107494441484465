@import "src/styles/animations";

.container {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 76px;
  width: 100%;
  min-width: 320px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 0 30px;
  z-index: 1100;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  &Hidden {
    .navLink {
      animation-name: fadeAndMoveUp;
      animation-timing-function: linear;
      animation-duration: 0.28s;
    }

    .leftBar {
      animation: fadeAndMoveFromBaseToRight100 0.28s linear both;
    }

    .logoMob,
    .langMob {
      transition: opacity 0.28s linear;
      animation: none;
      opacity: 0;
    }
  }
}

.logoMob {
  display: none;
  width: 100px;
  animation: fadeIn 0.3s ease-in-out 0.8s both;

  svg {
    display: block;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-right: 200px;
}

.navLink {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #313336;
  text-decoration: none;
  user-select: none;
  animation-name: showAndMoveDown;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  padding-top: 4px;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #0077E4;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
    border-bottom: 4px solid #0077E4;
  }

  &Active {
    color: #0077E4;

    &:after {
      transform: translateY(4px);
    }
  }

  &WithMobAnimation {
    animation-name: showAndMoveUp;
  }
}

.langMob {
  display: none;
  justify-content: center;
  margin-top: 60px;
}

.leftBar {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 36px;
}

.langLink {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  background-color: #0077E4;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  z-index: 20;

  &:not(:first-child) {
    margin-left: 5px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #fff;
    }
  }

  &Active {
    background-color: #0066C4;
    color: #fff;
  }
}

.arrow {
  position: absolute;
  width: 50vw;
  height: 100%;
  top: 0;
  left: 18px;
  background-color: #0077E4;
  animation-name: moveLeft500;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  animation-delay: 1.5s;
  animation-fill-mode: both;
  z-index: 10;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    border-top: 38px solid transparent;
    border-bottom: 38px solid transparent;
    border-right: 18px solid #0077E4;
  }
}

.menuBtnMob {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  animation: scaleInAndBounce 0.5s ease-in-out 1.6s both;
  z-index: 20;

  div {
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    height: 2px;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s ease-in;
    border-radius: 10px;

    &:first-child {
      transform: translateY(-8px);
      transition: all 0.4s ease-in-out;
    }

    &:last-child {
      transform: translateY(8px);
      transition: all 0.4s ease-in-out;
    }
  }

  &Active {
    div {
      transform: rotate(135deg) scale(0);

      &:first-child {
        transform: translateY(0) rotate(-135deg);
      }

      &:last-child {
        transform: translateY(0) rotate(135deg);
      }
    }
  }
}

@media (max-width: 1024px) {
  .nav {
    padding-right: 100px;
  }
}

@media (max-width: 900px) {
  .container {
    height: 68px;
  }

  .logoMob {
    display: block;
  }

  .nav {
    position: fixed;
    display: block;
    height: calc(100vh - 68px);
    width: 100%;
    top: 68px;
    left: 0;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    transform: translateX(-100%);
    padding: 40px 30px;
    z-index: 1100;

    &OpenedMob {
      transform: translateX(0);

      .langLink {
        animation-name: showAndMoveUp;
      }
    }
  }

  .navLink {
    display: block;
    height: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0;

    &:after {
      display: none;
    }
  }

  .langMob {
    display: flex;
  }

  .leftBar {
    padding-left: 46px;
  }

  .langLink {
    display: none;
    color: #0066C4;
    background-color: #fff;
    border: 1px solid transparent;

    &:not(:first-child) {
      margin-left: 25px;
    }

    &:hover {
      color: #0066C4;
    }

    &Mob {
      display: flex;
    }

    &Active {
      border: 1px solid #0066C4;
    }
  }

  .menuBtnMob {
    display: block;
  }

  .arrow {
    animation-delay: 1s;

    &:before {
      border-top: 34px solid transparent;
      border-bottom: 34px solid transparent;
      border-right: 16px solid #0077E4;
    }
  }
}

@media (max-width: 768px) {
  .container {
    height: 50px;
  }

  .nav {
    height: calc(100vh - 50px);
    top: 50px;
  }

  .arrow {
    &:before {
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-right: 14px solid #0077E4;
    }
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 30px 0 16px;
  }
}
