.container {
  display: flex;
  min-height: 100vh;
  max-width: 1200px;
  transition: opacity 0.28s ease-in-out;
  padding: 55px 0 65px;
  margin: 0 auto;

  &Hidden {
    opacity: 0;
  }
}

.toListBtn {
  display: none;
  align-items: center;
  height: 66px;
  font-size: 16px;
  line-height: 22px;
  color: #0077E4;
  border-bottom: 1px solid #E2E2E2;
  padding: 0 30px;
}

.arrowLeftIcon {
  width: 10px;
  min-width: 10px;
  height: 20px;
  margin-right: 12px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    padding-top: 0;
  }

  .toListBtn {
    display: flex;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1880px;
  }
}
