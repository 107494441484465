@import 'src/styles/common';
@import 'src/styles/animations';

.container {
  overflow: hidden;
  position: relative;
  color: #fff;
  animation: fadeIn 1s ease-out 0.2s both;
  padding: 70px 120px 130px;
  z-index: 10;
}

.bgLayoutWrap {
  position: absolute;
  left: -5%;
  top: -5%;
  width: 110%;
  height: 110%;
  z-index: -1;
}

.bgLayout {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("./img/intro-bg.jpg");
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation: scaleAndRotate 25s ease-in-out both infinite;
  z-index: 10;
}

.boilers {
  position: absolute;
  display: flex;
  align-items: center;
  top: 30%;
  left: 42%;
  width: 40%;
  height: 40%;
  z-index: 20;
}

.boilerWrap {
  animation-name: showAndMoveUp30;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}

.boiler1,
.boiler2,
.boiler3 {
  user-select: none;
  max-width: 90%;
  height: auto;
  margin-left: 30px;
}

.logo {
  display: block;
  height: 83px;
  animation: scaleAndFadeIn 0.8s ease-out 0.8s both;
}

.titleWrap {
  animation: showAndMoveUp30 0.6s ease-out 1.5s both;
  margin: 35px 0 45px;
}

.title {
  transition: all 20ms linear;
  max-width: 468px;
  font-weight: 600;
  font-size: 44px;
  line-height: 60px;
}

.descriptionWrap {
  animation: showAndMoveDown30 0.6s ease-out 1.5s both;
}

.description {
  font-size: 25px;
  line-height: 34px;
}

.eclipse {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 24px;
}

@media (max-width: 1024px) {
  .container {
    padding: 60px 60px 100px;
  }

  .logo {
    height: 60px;
  }

  .title {
    max-width: 430px;
    font-size: 40px;
    line-height: 52px;
  }

  .description {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 768px) {
  .container {
    background-position: 40% 50%;
    padding: 40px 50px 80px;
  }

  .logo {
    display: none;
  }

  .title {
    max-width: 280px;
    font-size: 27px;
    line-height: 37px;
    margin: 0 0 40px;
  }

  .description {
    font-size: 18px;
    line-height: 26px;
  }

  .eclipse {
    height: 12px;
  }
}

@media (max-width: 480px) {
  .container {
    background-image: url("./img/intro_bg_mobile.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 132px 24px 130px;
  }

  .bgLayoutWrap {
    display: none;
  }

  .description {
    font-size: 16px;
    line-height: 21px;
  }
}
