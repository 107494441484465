.container {
  width: 100%;
  background-color: #0078e9;
  background-image: url(./img/boiler-bg.jpg);
  background-attachment: fixed;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  padding: 90px 30px 115px;
  margin-top: -120px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 92px auto 0;
}

.descriptionWrap {
  width: 56%;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  &:not(:first-child) {
    margin-top: 28px;
  }
}

.siemensImgWrap {
  width: 34%;
}

.siemensImg {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  .container {
    padding: 90px 30px 85px;
    margin-top: -100px;
  }

  .content {
    margin-top: 60px;
  }

  .descriptionWrap {
    width: 55%;
  }

  .siemensImgWrap {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 60px 40px 65px;
    margin-top: 50px;
  }

  .content {
    display: block;
    margin-top: 40px;
  }

  .descriptionWrap {
    width: 100%;
  }

  .siemensImgWrap {
    width: 60%;
    margin: 20px auto 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 50px 20px 55px;
    margin-top: 30px;
  }

  .content {
    margin-top: 25px;
  }

  .description {
    font-size: 14px;
    line-height: 20px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

@media (max-width: 370px) {
  .container {
    padding: 40px 20px 45px;
  }

  .content {
    margin-top: 20px;
  }

  .description {
    font-size: 12px;
    line-height: 16px;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}
