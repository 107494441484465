.check {
  display: block;
  margin: 0 auto;
}

.description {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 36px;
}
