.element {
  max-width: 1040px;
  margin: 0 auto;

  &Active {
    .list {
      display: flex;
    }

    .item {
      background-color: #0077E4;
      color: #fff;
      background-image: url(./img/Up.svg);
      background-repeat: no-repeat;
      background-position: left;

      &:hover {
        background-color: #359AF8;
      }
    }
  }
}


.list {
  display: none;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-bottom: 1px solid #E2E2E2;
  padding-left: 6%;
  padding-top: 32px;
  padding-bottom: 40px;
  margin: 0;
}

.listItem {
  width: 30%;
  color: #0077E4;
}

.listItemBlock {
  color: #313336;
}

.contactsItem, .address {
  font-size: 16px;
  line-height: 32px;
  color: #313336;
}

.officeName {
  font-weight: 600;
}

.contact {
  color: #0077E4;
}

.item {
  position: relative;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #313336;
  text-decoration: none;
  background-image: url(./img/Down.svg);
  background-repeat: no-repeat;
  background-position: left;
  border-bottom: 1px solid #E2E2E2;
  padding: 18px 56px;
  margin: 0 auto;

  &:hover {
    background-color: #e6f2fc;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .contactsItem {
    font-size: 14px;
    line-height: 28px;
  }
}

@media (max-width: 650px) {
  .list {
    flex-direction: column;
    align-items: center;
    padding: 28px 0;
  }

  .listItem {
    width: 42%;
    margin-bottom: 20px;
  }

  .contactsItem {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 480px) {
  .list {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20%;
  }

  .listItem {
    width: 80%;
    margin-bottom: 18px;
  }

  .contactsItem, .address {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 370px) {
  .list {
    padding-left: 15%;
  }
}
