@import "src/styles/animations";

.container {
  padding: 80px 30px 110px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 64px auto 0;
}

.serviceImgWrap {
  width: 36%;
}

.serviceImg {
  width: 100%;
  height: auto;
}

.text {
  max-width: 464px;
  font-size: 16px;
  line-height: 28px;
  margin-left: 90px;
}

.serviceLink {
  color: #0077E4;
  text-transform: uppercase;
}

.tape1 {
  animation: dashOffsetReverse 20s linear infinite;
}

.tape2 {
  animation: dashOffset 20s linear infinite;
}

.largeGear {
  animation: rotate 20s linear 0.4s infinite;
  transform-origin: center;
  transform-box: fill-box;
}

.smallGearWrap {
  transform: translate(-6px, 4px);
}

.smallGear {
  animation: rotateReverse 20s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@media (max-width: 1024px) {
  .container {
    padding: 60px 30px 70px
  }

  .text {
    max-width: 410px;
    margin-left: 45px;
  }

  .serviceImgWrap {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 50px 30px;
  }

  .content {
    flex-direction: column;
    margin-top: 50px;
  }

  .serviceImgWrap {
    width: 43%;
    margin: 0 auto;
  }

  .text {
    max-width: 464px;
    line-height: 24px;
    margin: 30px auto 0 ;
  }
}

@media (max-width: 480px) {
  .content {
    margin-top: 30px;
  }

  .serviceImgWrap {
    width: 60%;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 25px;
  }
}

@media (max-width: 370px) {
  .content {
    margin-top: 20px;
  }

  .text {
    font-size: 14px;
    margin-top: 20px;
  }
}
