@import "src/styles/animations";

.content {
  max-width: 1040px;
  padding-top: 72px;
  margin: 0 auto 92px;
}

.horizontalDividerWrap {
  display: flex;
  align-items: center;
  animation: showAndMoveDown30 0.3s linear 0.65s both;
  margin-bottom: 38px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;
  margin-right: 8px;
}

.horizontalDivider {
  flex-grow: 1;
  width: 100%;
  height: 1px;
  background-color: #E2E2E2;
  margin-top: 3px;
}

.officeWrap {
  animation-name: showAndMoveDown;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@media (max-width: 1024px) {
  .content {
    padding: 60px 30px;
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 18px;
    line-height: 24px;
    white-space: normal;
  }

  .content {
    padding: 40px 0;
  }
}
