@import "src/styles/animations";

.content {
  max-width: 1040px;
  background: #f5f5f5;
  animation: fadeIn 0.3s linear 1.4s both;
  padding: 58px 0 72px;
  margin: 0 auto;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  margin-bottom: 46px;
}

.form {
  max-width: 456px;
  margin: 0 auto;
}

.inputWraper {
  margin-bottom: 16px;
}

.label {
  font-size: 14px;
  line-height: 28px;
}

.input {
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 36px;
  border: 1px solid #adadaf;
  padding-left: 8px;

  &Error {
    border-color: #D21232;
  }

  &Success {
    border-color: #adadaf;
    background-image: url(./img/Done.svg);
    background-position: 98%;
    background-repeat: no-repeat;
    padding-right: 10%;
  }
}

.textarea {
  width: 100%;
  min-height: 216px;
  font-family: 'Open Sans', sans-serif;;
  font-size: 16px;
  line-height: 28px;
  border: 1px solid #adadaf;
  padding: 8px;

  &Error {
    border-color: #D21232;
  }

  &Success {
    border-color: #adadaf;
  }
}

.input:focus, .textarea:focus {
  border-color: #0077E4;
}

.submitBtn {
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 28px;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  background-color: #0077E4;
  border: 7px solid #fff;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    width: calc(100% + 13px);
    height: calc(100% + 13px);
    border: 1px solid rgba(0, 119, 228, 0.5);
  }

  &Disabled {
    background-color: #eee;
    &::after {
      border: 1px solid #ccc;
    }
  }

  &:hover {
    background-color: #359AF8;
  }
}

.errors {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #D21132;
  font-size: 14px;
  line-height: 19px;
  padding: 8px 12px;
  margin-bottom: 20px;
  border-radius: 4px;  
}

.errorDescription {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #313336;
  margin-bottom: 5px;
}

.errorSvg {
  margin-right: 5px;
}

@media (max-width: 1024px) {
  .content {
    max-width: 904px;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 48px 30px 60px;
  }

  .textarea {
    min-height: 186px;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 40px 30px 45px;
  }

  .title {
    margin-bottom: 32px;
  }

  .label {
    font-size: 12px;
    line-height: 20px;
  }

  .textarea {
    min-height: 158px;
  }
}

@media (max-width: 370px) {
  .content {
    padding: 40px 20px 45px;
  } 

  .inputWraper {
    margin-bottom: 14px;
  }

  .textarea {
    min-height: 120px;
  }

  .buttonContact {
    width: 96%;
  }
}
