.container {
  position: relative;
  width: 100%;
  background-image: url("./img/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  transition: opacity 0.28s ease-in-out;
  padding: 113px 30px 26px;

  &Hidden {
    opacity: 0;
  }
}

.eclipse {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 24px;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  max-width: 423px;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  font-size: 28px;
  line-height: 40px;
  margin: 0 auto;
}

.text {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 28px;
  margin-top: 44px;
}

.textSecond, .textFirst {
  width: 48%;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 46px auto;
}

.madeBy {
  text-align: center;
  line-height: 34px;
  padding-top: 26px;
}

.linkLogge {
  position: relative;
  color: #fff;
  font-weight: 600;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &:after {
        transition: all 0.3s ease-in-out 10s;
        opacity: 1;
      }
    }
  }

  &:after {
    opacity: 0;
    display: block;
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    content: "| Darya M. :)";
    left: calc(100% + 5px);
    top: calc(50% - 10px);
    white-space: nowrap;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1024px) {
  .container {
    padding-top: 70px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-top: 50px;
  }

  .text {
    line-height: 22px;
    margin-top: 28px;
  }

  .madeBy {
    margin-top: 25px;
  }

  .eclipse {
    height: 12px;
  }

  .linkLogge {
    &:after {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .container {
    position: relative;
    padding: 50px 25px 68px;
  }

  .header {
    max-width: 230px;
    font-size: 18px;
    line-height: 25px;
  }

  .text {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .textFirst, .textSecond {
    width: 100%;
  }

  .paragraph {
    margin-bottom: 25px;
  }

  .textSecond {
    margin-bottom: 38px;
  }

  .divider{
    position: absolute;
    bottom: 70px;
    left: 0;
  }

  .eclipse {
    height: 6px;
  }
}

@media (max-width: 370px) {
  .container {
    padding-top: 35px;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
  }
}
