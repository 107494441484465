.linkWrap {
  position: relative;

  &:hover {
    .link {
      transition: color 0.2s ease-in-out;
      color: #0077E4;
    }
  }

  &Active {
    &:hover {
      .link {
        color: #fff;
      }
    }

    .link {
      color: #fff;
    }

    .arrow {
      transition: transform 0.4s ease-out;
      transform: translateX(0);
    }
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 76px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #313336;
  transition: color 0.3s ease-in-out;
  padding: 0 30px 0 60px;
  z-index: 30;
}

.arrow {
  position: absolute;
  width: 50vw;
  height: 76px;
  top: 0;
  right: 0;
  background-color: #0077E4;
  transition: transform 0.4s ease-in;
  transform: translateX(calc(-50vw - 20px));
  z-index: 10;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    border-top: 38px solid transparent;
    border-bottom: 38px solid transparent;
    border-left: 18px solid #0077E4;
  }
}

@media (max-width: 1024px) {
  .linkWrap {
    background-image: url(./img/arrow-right.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 36px;

    &MobHidden {
      display: none;
    }
  }

  .link {
    border-bottom: 1px solid #E2E2E2;
    font-size: 18px;
    line-height: 25px;

    &Active {
      display: none;
      border-bottom: none;
      border-top: none;
    }
  }

  .arrow {
    display: none;
  }
}

@media (max-width: 480px) {
  .link {
    padding-left: 20px;
  }
}
